import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ArticleService } from "src/app/services/article.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { SetItemGroup } from "src/app/interfaces/setitems/set-item-group";
import { Price } from "src/app/interfaces/price";
import * as _ from "underscore";
import { ApplicationService } from "src/app/services/application.service";
import { BasketService } from "src/app/services/basket.service";
import { AlertService } from "src/app/services/alert.service";
import { HelperService } from "src/app/services/helper.service";
import { SearchService } from "src/app/services/search.service";
import { TranslateService } from "@ngx-translate/core";
import { BasketArticle } from "src/app/interfaces/basketArticle";
@Component({
  selector: "app-set-items",
  templateUrl: "./set-items.component.html",
  styleUrls: ["./set-items.component.scss"],
})
export class SetItemsComponent implements OnInit {
  @Output() setitemUpdated: EventEmitter<BasketArticle> = new EventEmitter<BasketArticle>();
  @Input() article: Article;
  @Input() articleCode: string;
  @Input() showDetailed: boolean;
  @Input() preSelectedArticles: Article[];
  @Input() basketId = -1;
  @Input() quantity = 1;
  @Input() companyId: number;
  @Input() incomplete: boolean = true;

  groups: SetItemGroup[];
  nettPriceSum: Price;
  retailPriceSum: Price;
  showroomMode: boolean;
  valid: boolean;
  tooltipText: string;
  tooltipCurrencyText: string;

  get minOrderAmount() {
    return this.article.min_order_amount ? this.article.min_order_amount : 1;
  }

  constructor(
    private articleService: ArticleService,
    private applicationService: ApplicationService,
    private basketService: BasketService,
    private alertService: AlertService,
    private helperService: HelperService,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["article"]) {
      if (this.article.single_article_code && this.article.setitem) {
        this.getSetItems();
      }
    }
  }

  ngOnInit() {
    this.nettPriceSum = new Price();
    this.nettPriceSum.value = 0;
    this.nettPriceSum.currency = "";
    this.retailPriceSum = new Price();
    this.retailPriceSum.value = 0;
    this.retailPriceSum.currency = "";
    this.getShowroomMode();

    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
    });
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  getSetItems(reset = true) {
    if (reset) {
      this.groups = Array<SetItemGroup>();
    }
    this.articleService
      .getSetItems(this.article)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const groups = apiResponse.result;
          const groupsWithArticles = Array<SetItemGroup>();
          if (groups && Array.isArray(groups)) {
            groups.forEach((group: SetItemGroup) => {
              if (group.articles.length) {
                groupsWithArticles.push(group);
              }
            });
            this.groups = groupsWithArticles;

            if (this.preSelectedArticles) {
              this.editSetitems();
            }
          } else {
            this.alertService.showErrorNotification(apiResponse.result);
          }
        }
      });
  }

  setIncomplete() {
    if (!this.incomplete) {
      this.getSetItems(false);
    }
  }

  calcTotal() {
    const nettSumPrice = new Price();
    const retailSumPrice = new Price();
    const selectedArticles: Article[] = _.pluck(this.groups, "selectedArticle");
    let netSum = 0;
    let retailSum = 0;

    selectedArticles.forEach((a) => {
      if (a) {
        retailSumPrice.currency = a.retail_price?.currency;
        nettSumPrice.currency = a.net_price?.currency;
      }
      if (typeof a !== "undefined" && a !== null && a.selected) {
        if (a && a.net_price) {
          nettSumPrice.currency = a.net_price?.currency;
          netSum += a.net_price?.value;
        }

        if (a && a.retail_price) {
          retailSumPrice.currency = a.retail_price?.currency;
          retailSum += a.retail_price?.value;
        }
      }
    });

    nettSumPrice.value = netSum;
    retailSumPrice.value = retailSum;
    this.nettPriceSum = nettSumPrice;
    this.retailPriceSum = retailSumPrice;
    this.valid =
      _.filter(this.groups, (g) => {
        return (
          typeof g.selectedArticle !== "undefined" && g.selectedArticle !== null
        );
      }).length === this.groups.length;

    setTimeout(() => {
      this.updateToolTip();
    }, 0);
  }

  updateToolTip() {
    this.tooltipCurrencyText = this.nettPriceSum.currency;
    this.tooltipText = String(
      (this.showroomMode
        ? this.retailPriceSum.value
        : this.nettPriceSum.value) * this.quantity
    );
  }

  quantityChange(tooltip = null) {
    setTimeout(() => {
      this.updateToolTip();
    }, 0);
    if (tooltip) {
      tooltip.close();
      setTimeout(() => {
        tooltip.open();
      }, 0);
    }
  }

  basketSelected(basketId) {
    this.basketId = basketId;
  }

  addToBasket(update = false) {
    const selectedArticles: Article[] = _.pluck(this.groups, "selectedArticle");
    const selectedArticleIds: string[] = _.pluck(
      selectedArticles,
      "single_article_code"
    );
    this.article.quantity = this.quantity;

    // As separate articles when send as incomplete has been checked
    if (this.incomplete) {
      const filteredArticles = _.filter(selectedArticles, (article) => {
        if (typeof article !== "undefined") {
          article.quantity = this.incomplete && article.quantity > this.quantity ? article.quantity : this.quantity;
        }
        return typeof article !== "undefined" && article !== null && article.selected;
      });
      if (filteredArticles.length) {
        this.basketService
        .addArticlesToBasket(filteredArticles, this.basketId)
        .then((apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            this.alertService.showInfoNotification(apiResponse.result);
          }
        });
      }
    } else {
      this.basketService
        .addSetItem(this.article, selectedArticleIds, this.basketId, update)
        .then((apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            this.alertService.showInfoNotification(update ? this.translateService.instant("SAVED") : apiResponse.result);

            if (update) {
              this.setitemUpdated.emit(apiResponse.result?.items)
            }
          }
        });
    }
  }

  // Edit from basket
  editSetitems() {
    const preSelectedArticles = _.pluck(
      this.preSelectedArticles,
      "single_article_code"
    );
    this.groups.forEach((group: SetItemGroup) => {
      group.articles.forEach((article: Article) => {
        if (preSelectedArticles.indexOf(article.single_article_code) >= 0) {
          group.selectedArticle = article;
        }
      });
    });
  }
}
