<div
  class="treeViewItemContainer d-flex d-lg-none align-items-center"
  (mouseleave)="itemLeaved()"
  (mouseenter)="articleHovered()"
  (click)="articleSelected(index, $event)"
  [ngClass]="listClass"
>

  <div class="custom-checkbox ms-3 ms-sm-0 mb-3 d-inline-block me-2">
    <div
      class="checkbox"
      [ngClass]="article.authorized_to_place_in_basket ? '' : 'disabled'"
    >
      <label>
        <input
          type="checkbox"
          class="selectForAdd"
          *ngIf="article.single_article_code && !article.setitem && showCheckbox"
          [disabled]="!article.authorized_to_place_in_basket"
          [(ngModel)]="article.selected"
          (change)="articleChecked(index, $event)"
        />
        <span class="box"></span>
      </label>
    </div>
  </div>
  <span class="imgContainer pe-2">
    <img
      [src]="article.thumbs ? article.thumbs[0] : placeholder"
    />
  </span>
  <div class="article-information" (click)="openModal()">
    <h5
      class="description"
      [innerHTML]="article['description']"
      [ngClass]="article['caption'] ? 'hasCaption' : ''"
    ></h5>
    <small
      class="caption"
      [innerHTML]="
        (article['caption'] ? article['caption'] : '') +
        ' ' +
        (article['season'] && !['RAL','ANL'].includes(companyGroupCode) ? article['season'] : '') +
        ' ' +
        (article.single_article_code
          ? article.single_article_code +
            (article.article_code_oem ? ' | ' + article.article_code_oem : '')
          : article.single_article_code)
      "
    ></small>
    <small *ngIf="showArticleCode"
      >({{ article.single_article_code
      }}{{
        article.article_code_oem ? " | " + article.article_code_oem : ""
      }})</small
    >


    <app-stock-status
      [showText]="false"
      *ngIf="article.stock_status < 7"
      [article]="article"
      class="position-relative"
    ></app-stock-status>

    <span *ngIf="article.retail_price">
      <app-retail-price
        [price]="article.retail_price"
        [showLabel]="true"
        class="d-block"
      ></app-retail-price>
      <app-lease-price
        *ngIf="article.lease_start_price && article.lease_start_price.value && article.is_lease"
        [showTable]="!article.has_flex_batt"
        [lowestPrice]="article.lease_start_price"
        [articleCode]="article.single_article_code"
        [leasePrices]="article.lease_price"
        class="d-block"
      ></app-lease-price>
    </span>
    <span [style.display]="article.gross_price ? 'block' : 'none'">
      <app-gross-price
        [price]="article.gross_price"
        [showLabel]="true"
        class="d-block"
      ></app-gross-price>
    </span>
    <span *ngIf="!article.hide_nett_price" class="d-block">
      <app-nett-price
        [showLabel]="true"
        [price]="article.net_price"
        [hasDifferentPrices]="article.has_different_prices"
        [volumePrices]="article.volume_price"
        class="d-block"
      >
      </app-nett-price>
    </span>
  </div>

  <app-article-links
    class="ms-auto"
    [article]="article"
    [mobile]="true"
  ></app-article-links>
</div>

<div class="d-none d-lg-block">
  <input
    type="checkbox"
    class="selectForAdd d-inline-block ms-1 ms-lg-2 mt-1"
    *ngIf="article.single_article_code && !article.setitem && showCheckbox"
    [disabled]="!article.authorized_to_place_in_basket"
    [(ngModel)]="article.selected"
    (change)="articleChecked(index, $event)"
  />
  <!-- <span *ngIf="article.composed" class="selectForAdd ms-2" (click)="articleExpanded()"><i class="material-icons">{{this.article.bom_articles &&
      this.article.bom_articles.length?'remove':'add'}}</i></span> -->
  <div
    class="d-inline-block treeViewItemContainer align-items-center"
    (mouseleave)="itemLeaved()"
    (mouseenter)="articleHovered()"
    (click)="articleSelected(index, $event)"
   
    [ngClass]="listClass"
  >
    <ng-template #info>
      <span
        class="imgContainer justify-content-center"
        [style.max-width]="imageSize + 200 + 'px'"
      >
        <img [src]="article['thumbs'][0]" style="max-height: 100px" /> </span
      ><br />
      <b *ngIf="article.retail_price">
        <app-retail-price
          [price]="article.retail_price"
          [showLabel]="true"
        ></app-retail-price> </b
      ><br />
      <b *ngIf="article.gross_price">
        <app-gross-price
          [price]="article.gross_price"
          [showLabel]="true"
        ></app-gross-price> </b
      ><br />
      <b *ngIf="article.net_price">
        <app-nett-price [price]="article.net_price" [volumePrices]="article.volume_price"></app-nett-price>
      </b>
    </ng-template>

    <div class="row mx-0 d-flex justify-content-around">
      <div
        class="col-2 col-sm-2 px-0"
        [style.max-width]="imageSize + 20 + 'px'"
      >
        <span
          class="imgContainer justify-content-center pt-2"
          [style.min-width]="imageSize + 20 + 'px'"
          [style.min-height]="imageSize + 'px'"
        >
          <img
            class="mt-1"
            [src]="article.thumbs ? article.thumbs[0] : placeholder"
            [style.max-width]="imageSize + 'px'"
            [style.max-height]="imageSize + 'px'"
          />
        </span>
      </div>
      <div class="d-flex col-9 col-sm-9 pt-2 px-0 align-items-center">
        <span
          class="description me-1"
          [ngClass]="article['caption'] ? 'hasCaption' : ''"
          (dblclick)="openModal()"
        >
          <span [innerHTML]="article['description']" class="me-2 fw-medium"></span>
          <ng-container
            *ngIf="article['caption'] || (article['season'] && !['RAL','ANL'].includes(companyGroupCode)) || article.single_article_code"
          >
            <br />
            <small
              class="caption opacity-75"
              [innerHTML]="
                (article['caption'] ? article['caption'] : '') +
                ' ' +
                (article['season'] && !['RAL','ANL'].includes(companyGroupCode) ? article['season'] : '') +
                ' ' +
                (article.single_article_code
                  ? article.single_article_code +
                    (article.article_code_oem
                      ? ' | ' + article.article_code_oem
                      : '')
                  : '')
              "
            ></small>

            <app-highlight-badge
              [article]="article"
              class="position-absolute"
              *ngIf="
                (article.stock_status < 3 || article.setitem) &&
                (article.promotion ||
                  article.new ||
                  article.setitem ||
                  article.dropshipment || article.is_lease)
              "
            >
            </app-highlight-badge>
          </ng-container>
          <small class="d-block qty" *ngIf="article.quantity && !showQuantityInput"
          >{{"QUANTITY"|translate}}: {{article.quantity}}</small
        >
        </span>
        <span class="me-1 d-inline-flex qty-input" *ngIf="showQuantityInput">
          <p-inputNumber inputId="integeronly" type="number" numberOnly [allowDecimals]="false" [(ngModel)]="article.quantity"
          [inputStyle]="{'width': '56px'}" [inputStyleClass]="'me-1 ps-2 pe-0'"
          aria-label="Input group example" aria-describedby="btnGroupAddon" [min]="article.min_order_amount ? article.min_order_amount : 1" max="9999"  ngDefaultControl [showButtons]="true"></p-inputNumber>
        </span>
        <span class="d-none d-sm-inline-flex align-items-center ms-auto mb-2" style="min-width: 200px;">
          <app-retail-price
          *ngIf="showPrices"
          class="ms-auto"
          [price]="article.retail_price"
          [hideLabel]="true"
        >

        </app-retail-price
        ><span class="px-1" *ngIf="showPrices"> / </span>
          <app-nett-price
            *ngIf="showPrices"
            class="ms-auto ms-2"
            [price]="article.net_price"
            [volumePrices]="article.volume_price"
            [article]="article"
            >
          </app-nett-price>
           <app-stock-status
            [showText]="false"
            *ngIf="article.stock_status < 7"
            [article]="article"
            class="position-relative ms-2"
          >
          </app-stock-status>
        </span>
      </div>
    </div>
  </div>
</div>
<ng-content></ng-content>
