import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { SetItemGroup } from "src/app/interfaces/setitems/set-item-group";
import { ApplicationService } from "src/app/services/application.service";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "app-set-item-group",
  templateUrl: "./set-item-group.component.html",
  styleUrls: ["./set-item-group.component.scss"]
})
export class SetItemGroupComponent implements OnInit {
  @Input() group: SetItemGroup;
  @Input() showSelected: boolean;
  @Input() showQuantityInput: boolean;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() companyId: number;
  articles: Article[];
  isDach: boolean;
  constructor(private searchService: SearchService, private applicationService: ApplicationService) {}

  ngOnInit() {
    this.articles = Array<Article>();
    this.isDach = this.applicationService.isDach();
    if (this.group.articles && this.group.articles.length === 1 && !this.applicationService.isDach()) {
      this.group.selectedArticle = this.group.articles[0];
      this.group.selectedArticle.uncheckable = true;
      this.group.selectedArticle.selected = true;
    } else {
      this.articles = this.group.articles;
    }
    this.searchService.getNettPrices(this.group.articles, this.companyId).then(() => {
      this.selectionChanged.emit("");
    })

  }

  selectArticle(data) {
    this.group.selectedArticle = data;
    this.selectionChanged.emit("");
  }

  removeCheckArticle(article) {
    if (this.group.articles.length > 1 || this.isDach) {
      this.group.selectedArticle = null;
    }
    this.selectionChanged.emit("");
  }
}
