<div class="container">
  <div class="mx-0 row">
    <div
    class="px-0 p-sm-0 pe-sm-2 col-md-12 ps-sm-3 basketContainer pb-3"
      *ngIf="basket; else noResults"
    >
      <div class="row info">
        <div class="col-12 px-4">
          <div class="row">
            <div class="col-12 col-xl-5">
              <h1 class="d-inline-flex mb-0">
                <app-header-icon iconName="shopping_basket"></app-header-icon>
                {{ "BASKET" | translate }} &centerdot;
                {{ basket.items?.length }}
                {{
                  (basket.items?.length === 1 ? "ARTICLE" : "ARTICLES") | translate
                }}
                <span
                  class="ms-2 px-4 py-1 me-1 badge bg-warning badge-pill d-flex align-items-center"
                  *ngIf="isPreorderBasket"
                >
                  {{ "PREORDER" | translate }}
                </span>
              </h1>
              <h2
                *ngIf="!isPreorderBasket && !basket.locked; else preorderHeader"
                class="d-block ms-auto my-3 my-sm-0 pt-sm-4 mb-sm-2 subHeader clickable withOpacity editable"
                (click)="!basket.deleted ? editName() : undefined"
              >
                <ng-container *ngIf="!isPreorderBasket">{{ basket.name }}</ng-container>
                <i *ngIf="!isPreorderBasket" class="material-icons ms-1">edit</i>
              </h2>
            </div>
            <div class="col-12 col-xl-6 pb-2 me-auto">
              <div class="d-flex mt-sm-4 flex-column flex-lg-row justify-content-end">
                <div
                  *ngIf="!basket.locked && !referenceDisabled"
                  class="ps-0 pe-0 col-md-4 pe-sm-2"
                  [ngClass]="{
                    'col-md-4': shippingAddressGroup?.shipping_addresses?.length || shippingAddressGroupParts?.shipping_addresses?.length,
                    'col-md-5': !(shippingAddressGroup?.shipping_addresses?.length || shippingAddressGroupParts?.shipping_addresses?.length)
                  }"
                >
                  <b>{{ "REFERENCE" | translate }}</b>
                  <input
                    type="text"
                    class="mb-3 mt-2 form-control form-control-lg"
                    [(ngModel)]="basket.reference"
                    (change)="changeReference()"
                    [disabled]="basket.deleted"
                    style="height: 40px"
                    maxlength="75"
                  />
                </div>
                <div
                  class="pe-0 ps-sm-2 col-md-6 col-lg-6 pb-2"
                  *ngIf="!basket.locked && !basket.deleted && hasShippingAddresses && !applicationService.isPartnerPortal()"
                >
                  <b>{{ "SHIPPINGADDRESS" | translate }}</b>
                  <div class="d-flex flex-row align-items-center mt-2 w-100" *ngIf="shippingAddressGroup?.shipping_addresses?.length">
                    <label
                      class="me-2"
                      style="min-width: 140px"
                      *ngIf="showBicycleLabel"
                    >
                      {{ "BICYCLES" | translate }}
                    </label>
                    <app-select-shipping-address
                      *ngIf="(applicationService.getSelectCompanyGroupCode() !== 'WG' && shippingAddressGroup.shipping_addresses?.length) ||
                      (applicationService.getSelectCompanyGroupCode() === 'WG' && ((shippingAddressGroup.shipping_addresses?.length && groupedArticles?.bicycles?.items?.length || (!groupedArticles?.bicycles?.items?.length && !groupedArticles?.parts?.items?.length)) || isPreorderBasket))"
                      class="w-100"
                      [shippingAddresses]="shippingAddressGroup"
                      [showSelected]="true"
                      (shippingAddressSelected)="setShippingAddress($event)"
                      [selectedShippingAddress]="shippingAddressGroup.selected"
                      [type]="'dropdown'"
                      [showBlank]="shippingBlankOptions"
                      [selectBlank]="shippingBlankOptions"
                    ></app-select-shipping-address>
                  </div>

                  <div class="d-block mt-2" *ngIf="shippingAddressGroupParts?.shipping_addresses?.length && groupedArticles['parts']?.items.length">
                    <div class="d-flex flex-row align-items-center">
                      <label class="me-2" style="min-width: 140px">{{ "PARTS" | translate }}</label>
                      <app-select-shipping-address
                        [showSelected]="true"
                        [shippingAddresses]="shippingAddressGroupParts"
                        class="w-100"
                        [type]="'dropdown'"
                        (shippingAddressSelected)="setShippingAddressParts($event)"
                        [selectedShippingAddress]="shippingAddressGroupParts.selected"
                      ></app-select-shipping-address>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ngb-alert
            *ngIf="basket.locked"
            class="my-4"
            type="danger"
            [dismissible]="false"
            >{{ "BASKET_LOCKED" | translate }}</ngb-alert
          >

          <p
            *ngIf="isPreorderBasket"
            [innerHTML]="'PREORDER_INTRO_' + companyGroupCode | translate"
          ></p>

          <ng-template #preorderHeader>
            <app-cms-page-content
              [pageId]="
                preorderCMSPageIds[
                  applicationService.getSelectCompanyGroupCode()
                ]
              "
              *ngIf="
                applicationService.getSelectCompanyGroupCode() === 'WG' ||
                applicationService.getSelectCompanyGroupCode() === 'GHO'
              "
               class="mt-3 d-block"
            ></app-cms-page-content>
          </ng-template>
        </div>
      </div>

      <div class="position-relative">
        <!-- Locked basket overlay -->
        <div
          class="top-0 p-3 basket-locked position-absolute w-100 h-100 d-flex justify-content-center align-items-center m-n3"
          *ngIf="basket.locked"
        >
          <span class="me-2 material-icons text-danger"> lock </span>
          <span class="me-3">{{ "BASKET_LOCKED" | translate }}</span
          ><button
            class="btn btn-sm btn-outline-primary"
            (click)="unlockBasket()"
          >
            {{ "UNLOCK_BASKET" | translate }}
          </button>
        </div>

        <div class="row">
          <div
            class="col-12 col-xl-9 ps-0 d-none d-sm-flex justify-content-between mb-2"
            *ngIf="!isPreorderBasket && basket.items?.length"
          >
            <app-select-article-type
              class="d-flex"
              [hidden]="!showDealerOptions"
              (visibleArticleType)="visibleArticleType = $event"
            ></app-select-article-type>
            <div *ngIf="!applicationService.isPartnerPortal()">
              <div class="form-check my-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="compactCheckbox"
                  [(ngModel)]="compactMode"
                />
                <label class="form-check-label" for="compactCheckbox">
                  {{ "COMPACT" | translate }}
                </label>
              </div>
            </div>
          </div>
          <!-- Normal basket -->
          <div class="col-12 col-md-8 col-lg-9">
            <h3 *ngIf="!basket.items.length" class="mt-3">
              {{ "YOU_HAVE_NO_ARTICLES" | translate }}
            </h3>
            <ng-container *ngIf="!isPreorderBasket">
              <!-- Parts -->
              <app-grouped-articles
                [ngClass]="
                  !visibleArticleType || visibleArticleType === 'parts'
                    ? 'd-block'
                    : 'd-none'
                "
                *ngIf="
                  groupedArticles &&
                  groupedArticles['parts'] &&
                  groupedArticles['parts'].items.length
                "
                (contentChanged)="getBasket()"
                [groupedArticles]="groupedArticles['parts']"
                [header]="'PARTS_ACCESSORIES'"
                [type]="'parts'"
                [sum]="basketSum['parts']"
                [compact]="false"
                [basketId]="basket.id"
                [checkout]="false"
                [currency]="basket.total_price?.currency"
                [showShopDropdown]="basket.article_shop_dropdown"
                [showCheckoutOption]="true"
                [compactMode]="compactMode"
                [lockedDeliveryWeeks]="
                lockedWeeksParts
                "
                [basketLocked]="basket.locked"
              >
                <app-select-parts-delivery-week
                  select-week
                  [groupedArticles]="groupedArticles['parts']"
                  [basketId]="basketId"
                  (updating)="loaded = !$event"
                  *ngIf="
                    applicationService.getSelectCompanyGroupCode() === 'WG'
                  "
                ></app-select-parts-delivery-week>
              </app-grouped-articles>

              <!-- Bicycles -->
              <app-grouped-articles
                class="mt-3"
                [ngClass]="
                  !visibleArticleType || visibleArticleType === 'bicycles'
                    ? 'd-block'
                    : 'd-none'
                "
                *ngIf="
                  groupedArticles &&
                  groupedArticles['bicycles'] &&
                  groupedArticles['bicycles'].items.length
                "
                (contentChanged)="getBasket()"
                [groupedArticles]="groupedArticles['bicycles']"
                [header]="'BICYCLES'"
                [compactMode]="compactMode"
                [type]="'bicycles'"
                [sum]="basketSum['bicycles']"
                [compact]="false"
                [basketId]="basket.id"
                [checkout]="false"
                [currency]="basket.total_price?.currency"
                [showShopDropdown]="basket.article_shop_dropdown"
                [showCheckoutOption]="true"
                [lockedDeliveryWeeks]="lockedWeeksBikes"
                [basketLocked]="basket.locked"
              ></app-grouped-articles>
            </ng-container>

            <!-- Pre order -->
            <ng-container *ngIf="isPreorderBasket">
              <div class="d-flex row">
                <app-grouped-articles
                  class="col-md-12 d-block w-100 preorderGroup ps-0"
                  [style.order]="0"
                  *ngIf="
                    groupedArticlesByDirectDelivery &&
                    groupedArticlesByDirectDelivery.items.length &&
                    basketSumDirect
                  "
                  (contentChanged)="getBasket()"
                  (editPreorderArticle)="editPreorder()"
                  [groupedArticles]="groupedArticlesByDirectDelivery"
                  [allArticles]="basket.items"
                  [header]="'DELIVER_WHEN_POSSIBLE'"
                  [showCheckoutOption]="!individualPreorderCheckout"
                  [sum]="basketSumDirect['direct']"
                  [compact]="false"
                  [basketId]="basket.id"
                  [checkout]="false"
                  [groupId]="'direct'"
                  [type]="preorderType"
                  [preorder]="true"
                  [currency]="basket.total_price?.currency"
                  [showShopDropdown]="basket.article_shop_dropdown"
                  [lockedDeliveryWeeks]="basket.locked_delivery_week_all"
                  [basketLocked]="basket.locked"
                >
                </app-grouped-articles>
                <ng-container *ngFor="let m of totalMonths; let i = index">
                  <app-grouped-articles
                    class="col-md-12 d-block w-100 preorderGroup ps-0"
                    [style.order]="
                      groupedArticlesByMonth[m].items[0].delivery_year -
                      2000 +
                      '' +
                      applicationService.getTimeslotFromMonth(
                        m.toString() | slice : 4 : 6
                      )
                    "
                    *ngIf="
                      groupedArticlesByMonth &&
                      groupedArticlesByMonth[m] &&
                      groupedArticlesByMonth[m].items.length
                    "
                    (contentChanged)="getBasket()"
                    (editPreorderArticle)="editPreorder()"
                    [allArticles]="basket.items"
                    [showCheckoutOption]="showArticleCheckbox"
                    [groupedArticles]="groupedArticlesByMonth[m]"
                    [header]="
                      'MONTH_' +
                          applicationService.getTimeslotFromMonth(
                            m.toString() | slice : 4 : 6
                          )
                    "
                    [sum]="basketSumMonths[m.toString()]"
                    [compact]="false"
                    [basketId]="basket.id"
                    [checkout]="false"
                    [preorder]="true"
                    [groupId]="
                      applicationService.getTimeslotFromMonth(
                        m.toString() | slice : 4 : 6
                      )
                    "
                    [currency]="basket.total_price?.currency"
                    [showShopDropdown]="basket.article_shop_dropdown"
                    [type]="preorderType"
                    [lockedDeliveryWeeks]="basket.locked_delivery_week_all"
                    [basketLocked]="basket.locked"
                  >
                    {{ groupedArticlesByMonth[m].items[0].delivery_year }}
                  </app-grouped-articles>
                </ng-container>
                <app-grouped-articles
                  class="col-md-12 d-block w-100"
                  [style.order]="2998"
                  *ngIf="
                    groupedArticlesByMonth &&
                    groupedArticlesByMonth['demo'] &&
                    groupedArticlesByMonth['demo'].items.length
                  "
                  (contentChanged)="getBasket()"
                  (editPreorderArticle)="editPreorder()"
                  [allArticles]="basket.items"
                  [showCheckoutOption]="!individualPreorderCheckout"
                  [groupedArticles]="groupedArticlesByMonth['demo']"
                  [header]="'DEMO'"
                  [sum]="basketSumMonths['demo']"
                  [compact]="false"
                  [basketId]="basket.id"
                  [checkout]="false"
                  [preorder]="true"
                  [groupId]="'DEMO'"
                  [currency]="basket.total_price?.currency"
                  [showShopDropdown]="basket.article_shop_dropdown"
                  [type]="preorderType"
                  [lockedDeliveryWeeks]="basket.locked_delivery_week_all"
                  [basketLocked]="basket.locked"
                >
                </app-grouped-articles>
                <app-grouped-articles
                  class="col-md-12 d-block w-100"
                  [style.order]="2999"
                  *ngIf="
                    groupedArticlesByMonth &&
                    groupedArticlesByMonth['repair'] &&
                    groupedArticlesByMonth['repair'].items.length
                  "
                  (contentChanged)="getBasket()"
                  (editPreorderArticle)="editPreorder()"
                  [allArticles]="basket.items"
                  [showCheckoutOption]="!individualPreorderCheckout"
                  [groupedArticles]="groupedArticlesByMonth['repair']"
                  [header]="'REPAIR'"
                  [sum]="basketSumMonths['repair']"
                  [compact]="false"
                  [basketId]="basket.id"
                  [checkout]="false"
                  [preorder]="true"
                  [groupId]="'REPAIR'"
                  [currency]="basket.total_price?.currency"
                  [showShopDropdown]="basket.article_shop_dropdown"
                  [type]="type"
                  [lockedDeliveryWeeks]="basket.locked_delivery_week_all"
                  [basketLocked]="basket.locked"
                >
                </app-grouped-articles>
              </div>
              <div
                class="preorder"
                *ngIf="
                  basketArticlesByBrand &&
                  companyGroupCode !== 'GHO' &&
                  !showroomMode
                "
              >
                <app-basket-sum-by-brand
                  [basketArticlesByBrand]="basketArticlesByBrand"
                ></app-basket-sum-by-brand>
              </div>
            </ng-container>
          </div>

          <div class="col-12 col-md-4 col-lg-3 px-2 pt-3">
            <sticky
              id="sticky"
              #sticky
              [sticky-offset-top]="fixedNavBar ? 100 : 0"
              [sticky-media-query]="'min-width: 576px'"
              [style.min-width]="stickyWidth ? stickyWidth + 'px' : 0"
            >



              <ng-container *ngIf="companyGroupCode === 'RAL' && !showroomMode">
                <ng-container *ngTemplateOutlet="creditLimit"></ng-container>
              </ng-container>

              <div
                class="sum totalSum mt-4"
                *ngIf="
                  !isPreorderBasket &&
                  basket.items.length &&
                  basketSum.totalWithShipping > 0
                "
              >
                <ng-container *ngTemplateOutlet="totals"></ng-container>
              </div>
              <ngb-alert
                type="warning"
                [dismissible]="false"
                *ngIf="isPreorderBasket"
                ><strong>{{ preorderBasketExplanation }}</strong></ngb-alert
              >
              <ng-container
                *ngIf="
                  isPreorderBasket
                "
              >
                <button class="btn btn-dark" (click)="editPreorder()">
                  {{ "EDIT_ADD_ARTICLES" | translate }}
                </button>
              </ng-container>
            </sticky>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noResults>
      <p class="px-0 col-md-12" *ngIf="loaded">
        <ngb-alert [type]="'warning'" [dismissible]="false">{{
          "NO_RESULTS" | translate
        }}</ngb-alert>
      </p>
    </ng-template>
    <app-basket-skeleton-loader *ngIf="!loaded && !basket" />
  </div>
</div>
<app-bottom-navbar [fixed]="true" *ngIf="basket">
  <ng-container *ngTemplateOutlet="options"></ng-container>
</app-bottom-navbar>

<ng-template #options>
  <div
    class="ps-0 pe-0 col-6 col-md-5 pe-md-2 d-flex gap-2 flex-wrap"
    *ngIf="type !== 'wishlist'"
  >
    <a
      class="float-left me-2 btn btn-lg btn-dark"
      [routerLink]="'/' + companyGroupCode + '/baskets/'"
    >
      {{ "ALL_BASKETS" | translate }}
    </a>
    <button
      class="float-left me-2 btn btn-lg btn-outline-dark mt-md-0"
      (click)="basketOptionsPopup()"
      [disabled]="!basket"
      *ngIf="showDealerOptions"
    >
      {{ "OPTIONS" | translate }}
    </button>
  </div>
  <div
    class="ps-0 pe-0 pe-sm-2 col-6 col-md-7 ps-md-2 d-flex justify-content-end align-items-start"
  >
    <app-deliver-when-all-in-stock
      *ngIf="
        !isPreorderBasket &&
        selectedForBackorder &&
        applicationService.getSelectCompanyGroupCode() !== 'WG' &&
        applicationService.getSelectCompanyGroupCode() !== 'ANL' &&
        applicationService.getSelectCompanyGroupCode() !== 'COM'
      "
      (changed)="updateDelivery($event)"
      [state]="basket.complete_delivery_only"
      class="d-flex h-100"
    ></app-deliver-when-all-in-stock>

    <button
      class="btn btn-lg btn-success text-center"
      *ngIf="
        basket &&
        !isPreorderBasket &&
        groupedArticles &&
        groupedArticles['bicycles'] &&
        groupedArticles['parts'] &&
        showDealerOptions &&
        !basket.deleted
      "
      [ngClass]="[
        !basket.locked &&
        groupedArticles['bicycles'].checkoutCount +
          groupedArticles['parts'].checkoutCount >
          0
          ? ''
          : 'disabled',
        checkingOut ? 'loading' : ''
      ]"
      [disabled]="
        (basket.locked &&
          groupedArticles['bicycles'].checkoutCount +
            groupedArticles['parts'].checkoutCount ===
            0) ||
        disableCheckout ||
        !loaded || checkingOut
      "
      (click)="checkout(basket.id)"
    >
      <span>
        {{ "CHECKOUT" | translate }} ({{
          (visibleArticleType === "parts"
            ? 0
            : groupedArticles["bicycles"].checkoutCount) +
            (visibleArticleType === "bicycles"
              ? 0
              : groupedArticles["parts"].checkoutCount)
        }})</span
      >
    </button>
    <button
    class="btn btn-lg btn-success text-center"
    *ngIf="
      basket &&
      !isPreorderBasket &&
      groupedArticles &&
      groupedArticles['bicycles'] &&
      groupedArticles['parts'] &&
      !showDealerOptions &&
      !basket.deleted
    "
    [ngClass]="[
      !basket.locked &&
      groupedArticles['bicycles'].checkoutCount +
        groupedArticles['parts'].checkoutCount >
        0
        ? ''
        : 'disabled',
      checkingOut ? 'loading' : ''
    ]"
    [disabled]="
      (basket.locked &&
        groupedArticles['bicycles'].checkoutCount +
          groupedArticles['parts'].checkoutCount ===
          0) ||
      disableCheckout ||
      !loaded || checkingOut
    "
    (click)="checkout(basket.id)"
  >
    <span>
      {{ "INVOICE_SHIPPING_DETAILS" | translate }}</span
    >
  </button>
    <button
      class="btn btn-lg btn-success"
      *ngIf="
        basket &&
        isPreorderBasket &&
        groupedArticlesByMonth &&
        !individualPreorderCheckout &&
        applicationService.getSelectCompanyGroupCode() !== 'WG' &&
        applicationService.getSelectCompanyGroupCode() !== 'GHO' &&
        !basket.deleted
      "
      [ngClass]="!basket.locked && validPreorderSelection ? '' : 'disabled'"
      (click)="checkout(basket.id)"
      [disabled]="basket.locked || disableCheckout"
    >
      {{ "CHECKOUT" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #creditLimit>
  <app-credit-limit [currency]="basket.total_price?.currency" />
</ng-template>

<ng-template #totals>
  <ul
    class="pe-2 list-group list-group-flush col-md-12 px-sm-2 ps-3 ps-sm-0"
    *ngIf="basketSum.totalWithShipping > 0"
  >
    <li class="px-2 border-0 list-group-item px-md-0 bg-transparent">
      <div class="row">
        <div
          class="px-3 text-end col-md-12 shippingSum"
          *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'"
        >
          <ng-container *ngIf="basket.shipping_cost && basketHasAnyParts()">
            <i
              *ngIf="basketHasAnyParts()"
              class="material-icons clickable withOpacity"
              (click)="showShippingCost()"
              style="transform: translateY(5px)"
              >list_alt</i
            >
            {{ "SHIPPINGCOST" | translate }}
            <button
              type="button"
              class="p-0 me-1 btn btn-link custom-tooltip"
              ngbTooltip="{{ 'SHIPPING_COST_NOTICE' | translate }}"
            >
              <span class="material-icons"> help </span>
            </button>
          </ng-container>
          <span
            *ngIf="basket.shipping_cost && basketHasAnyParts()"
            [innerHTML]="
              basket.shipping_cost.value
                | currencyConvert : basket.shipping_cost?.currency
            "
          ></span>
        </div>
      </div>
      <div class="row">
        <div
          class="px-3 col-md-12"
          *ngIf="
            shipping_minimum_order_amount &&
            basketHasAnyParts() &&
            applicationService.getSelectCompanyGroupCode() !== 'WG'
          "
        >
          <p class="text-end free-shipment-notice">
            ({{ (applicationService.getSelectCompanyGroupCode() === 'ANL' ? 'FREE_SHIPPING_PA' : "FREE_SHIPMENT_FROM_NOTICE") | translate }}
            <strong
              ><span [innerHTML]="shipping_minimum_order_amount.value|currencyConvert:shipping_minimum_order_amount?.currency"></span> </strong
            >)
          </p>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div
        class="row"
        *ngIf="basket && basket.items && basket.items.length; else noResults"
      >
        <div
          class="px-3 col-md-12 totalSum d-flex flex-column justify-content-end align-items-end"
        >
          <div class="d-block w-100">
            {{ "PARTS_ACCESSORIES" | translate }} ({{
              groupedArticles["parts"].checkoutCount
            }}):
            <span [innerHTML]="basketSum['parts'] | currencyConvert : basket.total_price?.currency"></span>
          </div>
          <div class="d-block w-100">
            {{ "BICYCLES" | translate }} ({{
              groupedArticles["bicycles"].checkoutCount
            }}):
            <span [innerHTML]="basketSum['bicycles'] | currencyConvert : basket.total_price?.currency"></span>
          </div>
          <div
            class="d-block w-100 border-top border-dark-subtle total-text pt-2 mt-2"
          >
          <ng-container *ngIf="!loaded">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </ng-container>
            {{ "TOTAL" | translate }}:
            <span
              class="d-md-inline-block"
              [innerHTML]="
                basketSum.totalWithShipping
                  | currencyConvert : basket.total_price?.currency
              "
            ></span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</ng-template>
