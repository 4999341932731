import { Component, OnInit, SimpleChanges, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Article } from "src/app/interfaces/article";

@Component({
  selector: "app-set-item-modal",
  templateUrl: "./set-item-modal.component.html",
  styleUrls: ["./set-item-modal.component.scss"]
})
export class SetItemModalComponent implements OnInit {
  article: Article;
  articleCode: string;
  showDetailed: boolean;
  incomplete: boolean;
  preSelectedArticles: Article[];
  basketId = -1;
  quantity = 1;
  constructor(public modal: NgbActiveModal) {}
  ngOnInit() {}
}
