<ng-container>
  <td class="py-2 ps-1 pe-0 selectable" style="max-width: 30px; float: left">
    <input type="checkbox" [(ngModel)]="invoice.selected" [disabled]="disablePaymentSelection && !invoice.selected" (change)="invoiceSelectionChanged()">
  </td>
  <td class="py-2 fw-medium">
    {{invoice.invoice_number}}
  </td>
  <td class="py-2">
    {{invoice.invoice_date}}
  </td>
  <td class="py-2" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">
    <span class="badge bg-success ms-1"
      *ngIf="invoice.amount_paid === invoice.invoice_amount">{{'PAID'|translate}}</span>
    <ng-container *ngIf="invoice.amount_paid !== invoice.invoice_amount">{{invoice.due_date}}</ng-container>
  </td>
  <td class="py-2" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">
    {{numberFormat.formatDot(invoice.invoice_amount)}}<br />
    <small>{{'DISCOUNT_AMOUNT' | translate}}: {{numberFormat.formatDot(invoice.discount_amount)}}</small>
  </td>
  <td class="py-2" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">
    {{numberFormat.formatDot(invoice.open_amount)}}<br />
    <small>{{'AMOUNT_PAID' | translate}}: {{numberFormat.formatDot(invoice.amount_paid)}}</small>
  </td>
  <td class="py-2">
    <app-download-open-order [orderNumber]="invoice.ace_id" *ngIf="invoice.pdf_url || applicationService.getSelectCompanyGroupCode() !== 'WG'"></app-download-open-order>
  </td>
  <td class="py-2">
    {{invoice.accentry_id!=='-'?invoice.accentry_id:''}}
  </td>
  <td class="py-2">
    <button class="btn btn-outline-dark btn-sm me-2" (click)="viewInvoice()" *ngIf="invoice.ace_id || applicationService.getSelectCompanyGroupCode() !== 'RAL'" >{{"VIEW_INVOICE"|translate}}</button>
  </td>
</ng-container>
