import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ArticleModalComponent } from "src/app/components/modal-components/article-modal/article-modal.component";
import { NgbDropdownConfig, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-tree-view-item",
  templateUrl: "./tree-view-item.component.html",
  styleUrls: ["./tree-view-item.component.scss"]
})
export class TreeViewItemComponent implements OnInit {
  @Input() article: Article;
  @Input() index: number;
  @Input() listClass: string;
  @Input() imageSize = 32;
  @Input() showPrices: boolean;
  @Input() showArticleCode: boolean;
  @Input() showCheckbox: boolean = true;
  @Input() showQuantityInput: boolean = false;

  @Output() hoverArticle: EventEmitter<any> = new EventEmitter<any>();
  @Output() expandArticle: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectArticle: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkArticle: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemLeave: EventEmitter<any> = new EventEmitter<any>();
  assetsPath = "assets/images/";
  placeholder = "assets/images/part.svg";
  mobile: boolean = true;
  companyGroupCode = "";

  constructor(
    private ngbModal: NgbModal,
    private NgbDropdownConfig: NgbDropdownConfig,
    private applicationService: ApplicationService
    ) {}

  ngOnInit() {
    if (window.screen.width >= 992) {
      this.mobile = false;
    }

    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();

    if (
      this.article.article_type &&
      this.article.article_type.startsWith("F")
    ) {
      this.placeholder = this.assetsPath + "bicycle-solid.svg";
    } else {
      this.placeholder = this.assetsPath + "part.svg";
    }

    if (this.article.composed) {
      this.expandArticle.emit(this.article);
    }
  }

  articleHovered() {
    this.hoverArticle.emit(this.article);
  }

  articleExpanded() {
    if (this.article.bom_articles && this.article.bom_articles.length) {
      this.article.bom_articles = Array<Article>();
    } else {
      this.expandArticle.emit(this.article);
    }
  }

  articleSelected(index, event) {

    this.selectArticle.emit({
      article: this.article,
      event: event,
      index: index
    });
  }

  articleChecked(index, event) {
    this.checkArticle.emit(this.article);
  }

  itemLeaved() {
    this.itemLeave.emit();
  }

  openModal() {
    const modalRef = this.ngbModal.open(ArticleModalComponent, {
      size: "lg",
      container: "#modalContainer"
    });
    modalRef.componentInstance.article = { ...this.article };
    modalRef.componentInstance.modelId = this.article.id;
    modalRef.componentInstance.article.img = this.article.images;
    modalRef.componentInstance.showInstant();
  }
}
