<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'COMPOSE_SETITEM'|translate}}</h4>
  <app-quick-settings style="margin-top: -10px;" [placement]="'right'" class="pt-2 ms-4"></app-quick-settings>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <app-set-items [showDetailed]="true" [article]="article" [companyId]="article.company_id" [basketId]="basketId" [quantity]="quantity" (setitemUpdated)="modal.close()" [incomplete]="false"
    [preSelectedArticles]="preSelectedArticles"></app-set-items>
</div>
<div class="modal-footer">
  <span class="col-md-12 p-0">
    <button class="btn btn-dark " (click)="modal.dismiss('cancel click')">{{'CLOSE' | translate}}</button>
  </span>
</div>
