import { Component, Input, OnInit } from '@angular/core';
import { ShippingCostDetails } from 'src/app/interfaces/shipping-cost-details';

@Component({
  selector: 'app-shipping-cost-overview',
  templateUrl: './shipping-cost-overview.component.html',
  styleUrls: ['./shipping-cost-overview.component.scss']
})
export class ShippingCostOverviewComponent implements OnInit {
  @Input() shippingCosts: ShippingCostDetails[];
  @Input() selectedShippingOverview: boolean;
  @Input() type: string;
  @Input() total: number;

  grandTotal(shippingCost: number, total: string) {
    return shippingCost + Number(total);
  }

  restAmount(threshold: number) {
    return threshold - Number(this.total);
  }


  constructor() { }

  ngOnInit() {
  }

}
