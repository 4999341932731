<ng-container *ngIf="!showDetails">
  <td class="py-2 pe-2" style="max-width: 40px;">
    <app-article-image class="w-100 d-inline-block" *ngIf="orderLine.image" [imageUrl]="orderLine.image" [ngbTooltip]="tipContent">
    </app-article-image>
    <br *ngIf="!orderLine.image">
    <ng-template #tipContent
      > <app-article-image class="w-100 d-inline-block" *ngIf="orderLine.image" [imageUrl]="orderLine.image">
      </app-article-image></ng-template
    >
  </td>
  <td class="py-2" [ngClass]="applicationService.getSelectCompanyGroupCode() !== 'WG' ? 'clickable withOpacity' : ''"
    (click)="applicationService.getSelectCompanyGroupCode() !== 'WG' ? editOrder(orderLine) : null">
    <span class="description fw-medium" [innerHTML]="orderLine.article_description"></span><br />
    <ng-container *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">{{orderLine.article_code}}</ng-container>
    <span *ngIf="applicationService.getSelectCompanyGroupCode() === 'WG'" [articleQuickView]="orderLine.article_code">{{orderLine.article_code}}</span>
    <small class="d-block" *ngIf="orderLine.ean_code">EAN: {{orderLine.ean_code}}</small>
  </td>
  <td class="py-2 text-end text-nowrap">
    {{ 'QTY_ORDERED' | translate }}: {{orderLine.quantity}} <br />
    <small *ngIf="applicationService.getSelectCompanyGroupCode() !== 'LAP'">{{ 'NUMBER_TO_SEND' | translate }}: {{orderLine.quantity_to_send}}</small>

  </td>
  <td class="py-2 text-end text-nowrap">
    {{ 'NUMBER_IN_TRANSIT' | translate }}: {{orderLine.quantity_in_transit}}<br />
    <small *ngIf="applicationService.getSelectCompanyGroupCode() !== 'LAP'">{{ 'QUANTITY_PROCESSED' | translate }}: {{orderLine.quantity_delivered}} </small>
  </td>
  <td class="py-2">
    <ng-container *ngIf="orderLine.erp_id && applicationService.getSelectCompanyGroupCode() === 'WG'">
      {{'ORDER_NUMBER' | translate}}: <b class="clickable withOpacity" (click)="downloadOpenOrder()">
        <i class="material-icons text-primary clickable withOpacity"
          style="font-size: 16px; transform: translateY(2px);">
          picture_as_pdf
        </i>
        {{orderLine.erp_id}}</b>
    </ng-container>
    <ng-container *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">{{orderLine.erp_id}}<br />
    </ng-container>
    <small>{{orderLine.accentry_id}}</small>
  </td>
  <td class="py-2 reference" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'LAP' && applicationService.getSelectCompanyGroupCode() !== 'GHO'">
    <span *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">{{orderLine.order_reference}}</span>
    <span *ngIf="applicationService.getSelectCompanyGroupCode() === 'WG' && orderLine.line_id !== '-'">{{orderLine.line_id}}<br/></span>
    <span class="d-block" *ngIf="applicationService.getSelectCompanyGroupCode() === 'WG'">{{orderLine.remark}}</span>
  </td>

  <td class="py-2">
    <app-nett-price [price]="{value: orderLine.netto_price, currency: ''}"></app-nett-price><br />
    <small>
      <app-retail-price *ngIf="orderLine.consumer_price" [price]="{value: orderLine.consumer_price, currency: ''}">
      </app-retail-price>
    </small>
  </td>
  <td class="py-2" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">
    <input type="text" [(ngModel)]="orderLine.remark" name="remark" class="form-control form-control-sm"
      (change)="inlineEditOrder()">
  </td>

  <td class="py-2 clickable withOpacity" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">
    <div class="form-check form-switch">
      <input type="checkbox" class="form-check-input" id="toggle_sold_{{orderLine.line_id}}" name="sold"
        [(ngModel)]="orderLine.sold" (change)="inlineEditOrder()">
      <label class="form-check-label justify-content-end text-light"
        for="toggle_sold_{{orderLine.line_id}}"></label>
    </div>
  </td>

  <td style="min-width: 100px" class="py-2">
    <ng-container *ngIf="orderLine.desired_delivery_week && applicationService.getSelectCompanyGroupCode() !== 'RAL'">
      {{'DESIRED_WEEK' | translate }}: {{orderLine.desired_delivery_week}}<br />
    </ng-container>
    <span *ngIf="(applicationService.getSelectCompanyGroupCode() !== 'WG' && applicationService.getSelectCompanyGroupCode() !== 'GHO') || showDeliveryWeek">{{'DELIVERY_WEEK' | translate }}: {{orderLine.delivery_week_or_date!=='DATE_UNKNOWN'?orderLine.delivery_week_or_date:'DATE_UNKNOWN'|translate}}
      <ng-container *ngIf="orderLine.old_delivery_week">({{ 'WAS' | translate }}: {{orderLine.old_delivery_week}})</ng-container>
    </span>
    <i class="d-block material-icons text-primary clickable withOpacity opacity-50 me-2"   placement="top" [ngbTooltip]="orderLine | shippingAddressText" triggers="click:blur" placement="left">
      local_shipping
    </i>
  </td>
  <td class="py-2">
    <ng-container
      *ngIf="((orderLine.backward_shifting_status < 3 && orderLine.backward_shifting_weeks) || orderLine.can_push_fordward_delivery_week) && applicationService.getSelectCompanyGroupCode() !== 'WG' && !orderLine.disallow_backwards_shifting">
      <i class="material-icons text-secondary clickable withOpacity" (click)="editOrder(orderLine)">
        swap_horiz
      </i>
      <i class="material-icons text-secondary clickable withOpacity" (click)="editOrder(orderLine)">
        calendar_today
      </i>
    </ng-container>
  </td>
  
  <td class="py-2" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">
 
    <i class="material-icons text-primary clickable withOpacity" (click)="editOrder(orderLine)">
      edit
    </i>
  </td>
</ng-container>
