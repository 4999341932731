import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { AddToBasketModalComponent } from "src/app/components/modal-components/add-to-basket-modal/add-to-basket-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketService } from "src/app/services/basket.service";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import { AlertService } from "src/app/services/alert.service";
import { InlineMatrixComponent } from "src/app/components/search-components/article-card/inline-matrix/inline-matrix.component";
import { ApplicationService } from "src/app/services/application.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { TranslateService } from "@ngx-translate/core";
import { DropshipmentService } from "src/app/services/dropshipment.service";
import { ConfirmDialogComponent } from "../../modal-components/confirm-dialog/confirm-dialog.component";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-add-to-basket-button",
  templateUrl: "./add-to-basket-button.component.html",
  styleUrls: ["./add-to-basket-button.component.scss"],
})
export class AddToBasketButtonComponent implements OnInit {
  @Output() buttonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() article: Article;
  @Input() size = "lg";
  @Input() hasInput = true;
  quantity = 1;
  totalSum = "";
  showroomMode = false;
  loading = false;

  constructor(
    private modalService: NgbModal,
    private basketService: BasketService,
    private alertService: AlertService,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private dropshipmentService: DropshipmentService
  ) {
    this.getShowroomMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  ngOnInit() {
    this.quantity = this.article.min_order_amount
      ? this.article.min_order_amount
      : 1;

    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
    });
  }

  addToBasket(event) {
    const modalRef = this.modalService.open(AddToBasketModalComponent, {
      centered: true,
      windowClass: "addToBasketModal modal-" + this.article.id,
      container: "#modalContainer",
      backdrop: "static",
      size:
        this.article.cross_sell_ecommerce || this.article.mandatory_battery
          ? "lg"
          : null,
    });
    modalRef.componentInstance.article = { ...this.article };
    modalRef.componentInstance.quantity = this.quantity;
    this.buttonClicked.emit(true);
    event.preventDefault();
  }

  quickAdd(event) {
    if (
      this.article.has_mandatory_item ||
      this.article.mandatory_battery ||
      (this.article.stock_status > 2 && this.article.article_type === "F")
    ) {
      this.addToBasket(event);
      return false;
    }
    this.loading = true;
    const basketArticle = {
      single_article_code: this.article.single_article_code,
      quantity:
        this.quantity > this.article.min_order_amount
          ? this.quantity
          : this.article.min_order_amount
          ? this.article.min_order_amount
          : this.quantity,
      remarks: "",
      delivery_week: this.parseDeliveryWeek(),
      description: this.article.description,
      caption: this.article.caption,
      nested_groups: this.article.nested_groups,
      company_id: this.article.company_id,
      thumbs: this.article.thumbs,
      retail_price: this.article.retail_price,
      warehouse: this.article.warehouse,
      id: this.article.id,
      sold: false,
    };

    // First check if the article is already in backorder (ANL) and if so let the user confirm
    this.basketService
      .getArticlesInBackorder(
        [this.article],
        this.applicationService.getSelectCompanyGroupCode()
      )
      .then((backorderArticles) => {
        this.loading = false;
        const promise = new Promise((resolve) => {
          if (!backorderArticles.length) {
            resolve(true);
          } else {
            const modalRef = this.modalService.open(ConfirmDialogComponent, {
              centered: true,
              size: "sm",
              windowClass: "confirmDialog",
            });
            modalRef.componentInstance.isConfirm = true;
            modalRef.componentInstance.title =
              this.translateService.instant("NOTE");
            modalRef.componentInstance.body = `${this.translateService.instant(
              "ARTICLES_IN_BACKORDER_ALERT"
            )}</br><br/>${this.translateService.instant("NUMBER")}: ${
              backorderArticles[0].quantity_in_backorder
            }. `;
            modalRef.componentInstance.buttonText = `${this.translateService.instant(
              "GOTO"
            )} ${this.translateService.instant("OPENORDERS").toLowerCase()}`;
               modalRef.componentInstance.confirmText =
                 this.translateService.instant("YES");
             modalRef.componentInstance.cancelText =
               this.translateService.instant("NO");
            modalRef.componentInstance.footerText =
              this.translateService.instant("BACKORDER_ALERT_FOOTER");
            modalRef.componentInstance.buttonLink = `${this.applicationService.getSelectCompanyGroupCode()}/financial/openorders?articleCode=${
              this.article.single_article_code
            }`;
            modalRef.componentInstance.confirmClicked.subscribe(() => {
              resolve(true);
            });
          }
        });
        promise.then(() => {
          this.loading = true;
          this.basketService
            .addArticlesToBasket([basketArticle])
            .then((apiResponse: ApiResponse) => {
              this.loading = false;
              if (apiResponse && apiResponse.success) {
                this.alertService.showInfoNotification(apiResponse.result);
              } else {
                apiResponse.result =
                  this.translateService.instant("ERROR");
              this.alertService.showErrorNotification(apiResponse.result);
              }
            });
          event.preventDefault();
        });
      });
  }

  parseDeliveryWeek() {
    return this.basketService.parseDeliveryWeek(this.article);
  }

  addToDropShipmentBasket() {
    this.dropshipmentService
      .addToDropshipmentBasket(this.article, this.quantity)
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.success) {
          this.alertService.showInfoNotification(apiResponse.result ? apiResponse.result : this.translateService.instant("ADDED_TO_DROPSHIPMENT"));
        }
      });
  }
}
